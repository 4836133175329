import React, { useState } from "react"
// import BlockContent from "@sanity/block-content-to-react"
import { motion, AnimatePresence } from "framer-motion"
import classnames from "classnames"
import slug from "slug"

/* Import Global Hook(s) */
import { useEditPosts } from "~queries/hooks"

/* Import Global Component(s) */
import Footer from "~components/footer/footer"
import Page from "~components/page/page"
import PageHeader from "~components/page/components/pageHeader/pageHeader"
import PageArticle from "~components/page/components/pageArticle/pageArticle"
import PageArticleSection from "~components/page/components/pageArticle/components/pageArticleSection/pageArticleSection"

import EditItemCard from '~components/pages/edits/components/editItemCard'


/* Import Local Style(s) */
import "./edit.scss"



const Edit = ({ location }) => {

	const pageTitle = 'Untitled Edit'

	const newsPostsByYear = useEditPosts()

	console.log(newsPostsByYear)

	// sort by publishdateDate

	return (<Page title={pageTitle} location={location}>
      <PageHeader title={pageTitle} location={location} />
      <PageArticle title={pageTitle} className="edit">
        <PageArticleSection>
        		<section className="grid">
	        		{newsPostsByYear.map(n => (
	        			<EditItemCard 
	        				key={n._id} 
	        				title={n.title} 
	        				date={n.publicationDate} 
	        				byline={n.byline} 
	        				color={n.color} 
	        				image={n.image}
	        			/>)
	        		)}
        		</section>
        </PageArticleSection>
      </PageArticle>
      <Footer />
  	</Page>)

}

export default Edit